// Add this import statement

export default defineNuxtPlugin(() => {
  return {
    provide: {
      analytics: {
        event(name: string, props: any) {
          // @ts-ignore
          if (window.amplitude) window.amplitude.track(name, props)
          console.log('analytics.event', name, props)
        },
        userProps(props: any) {
          console.log('analytics.userProps', props)
        },
      },
    },
  }
})
