import { default as indexhGpynX1cdDMeta } from "/opt/buildhome/repo/src/pages/index.vue?macro=true";
import { default as activity_45levelMVm7vScNGzMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/activity-level.vue?macro=true";
import { default as are_45your_45motherwqBKXChEOoMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/are-your-mother.vue?macro=true";
import { default as birthdayBwbR7TMeUqMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/birthday.vue?macro=true";
import { default as body_45shape_45info0K6NmAsNIxMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/body-shape-info.vue?macro=true";
import { default as can_45you_45go_45to_45gymQjgBOWeJ2YMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/can-you-go-to-gym.vue?macro=true";
import { default as chapter_45body_45datauZHwIvdv5pMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/chapter-body-data.vue?macro=true";
import { default as chapter_45fitness_45analysisNKz7IQlR6OMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/chapter-fitness-analysis.vue?macro=true";
import { default as chapter_45goalSAOKQNTwiTMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/chapter-goal.vue?macro=true";
import { default as checkoutWk6YDz34CIMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/checkout.vue?macro=true";
import { default as child_45age2mhp1aq7bpMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/child-age.vue?macro=true";
import { default as current_45body_45shapeEIBJDKRObTMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/current-body-shape.vue?macro=true";
import { default as current_45weight40w6l34y9LMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/current-weight.vue?macro=true";
import { default as easily_45give_45upV571CF60VPMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/easily-give-up.vue?macro=true";
import { default as faster_45resultTgm84MekOaMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/faster-result.vue?macro=true";
import { default as feel_45unsatisfiedNHBsnTQKs7Meta } from "/opt/buildhome/repo/src/pages/onboarding/female/feel-unsatisfied.vue?macro=true";
import { default as fitness_45level3EKzgsoIzvMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/fitness-level.vue?macro=true";
import { default as flexibilityaA8tk4xqXiMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/flexibility.vue?macro=true";
import { default as focus_45area3WYq6Y3TsTMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/focus-area.vue?macro=true";
import { default as generate_45plannETAQHmR1tMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/generate-plan.vue?macro=true";
import { default as goal_45infoDWgH2wghbsMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/goal-info.vue?macro=true";
import { default as heightj1eJF9xXfbMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/height.vue?macro=true";
import { default as how_45often_45workoutvJeGLMLB3YMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/how-often-workout.vue?macro=true";
import { default as how_45will_45celebrateqzvayE5gqoMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/how-will-celebrate.vue?macro=true";
import { default as index8EFLeDYBqRMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/index.vue?macro=true";
import { default as main_45goalKQtQrIOft5Meta } from "/opt/buildhome/repo/src/pages/onboarding/female/main-goal.vue?macro=true";
import { default as motivation_45photo_451hRPgFtdXfAMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/motivation-photo-1.vue?macro=true";
import { default as motivation_45photo_4521XxLjwAxMMMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/motivation-photo-2.vue?macro=true";
import { default as motivation_45photo_4539tymTjMUy3Meta } from "/opt/buildhome/repo/src/pages/onboarding/female/motivation-photo-3.vue?macro=true";
import { default as motivationYencYRrbDrMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/motivation.vue?macro=true";
import { default as new_45datekOkwm2edqaMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/new-date.vue?macro=true";
import { default as payment9FQZYxT1LSMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/payment.vue?macro=true";
import { default as personal_45plan57a2VMlPWoMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/personal-plan.vue?macro=true";
import { default as pick_45up_45suitable_45workoutsGrZ9c2BxbfMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/pick-up-suitable-workouts.vue?macro=true";
import { default as pre_45focus_45areavlLwc5MTMdMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/pre-focus-area.vue?macro=true";
import { default as prefer_45workout8uS9tER38cMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/prefer-workout.vue?macro=true";
import { default as preferred_45workout_45levelM1rVDnKh1iMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/preferred-workout-level.vue?macro=true";
import { default as preferred_45workout_45typecRE15vhmTJMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/preferred-workout-type.vue?macro=true";
import { default as social_45proofTouJDqITfeMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/social-proof.vue?macro=true";
import { default as special_45event_45infoDedmoV02nMMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/special-event-info.vue?macro=true";
import { default as special_45eventqNY83OviuyMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/special-event.vue?macro=true";
import { default as stopping_45your_45goal_45infox9omyGGAXAMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/stopping-your-goal-info.vue?macro=true";
import { default as stopping_45your_45goalqeP7waMgv4Meta } from "/opt/buildhome/repo/src/pages/onboarding/female/stopping-your-goal.vue?macro=true";
import { default as subscribe_45for_45early_45birdsuvBj3buGLtMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/subscribe-for-early-birds.vue?macro=true";
import { default as suffered_45any_45discomfort3wIGSjusAcMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/suffered-any-discomfort.vue?macro=true";
import { default as target_45body_45shapeIKdDLfe3HEMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/target-body-shape.vue?macro=true";
import { default as target_45datekv5s8TcqQ7Meta } from "/opt/buildhome/repo/src/pages/onboarding/female/target-date.vue?macro=true";
import { default as target_45weightR1wNEXBGSFMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/target-weight.vue?macro=true";
import { default as we_45can_45make_45itbf7HAYQNStMeta } from "/opt/buildhome/repo/src/pages/onboarding/female/we-can-make-it.vue?macro=true";
import { default as gender6Mt1leShFCMeta } from "/opt/buildhome/repo/src/pages/onboarding/gender.vue?macro=true";
import { default as indexyicyoEXAIpMeta } from "/opt/buildhome/repo/src/pages/onboarding/index.vue?macro=true";
import { default as goalY5ZvjAdh2FMeta } from "/opt/buildhome/repo/src/pages/onboarding/male/goal.vue?macro=true";
import { default as index25lkwhsKibMeta } from "/opt/buildhome/repo/src/pages/onboarding/male/index.vue?macro=true";
import { default as onboardingntGYuEPJGWMeta } from "/opt/buildhome/repo/src/pages/onboarding.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingntGYuEPJGWMeta?.name,
    path: "/onboarding",
    meta: onboardingntGYuEPJGWMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/onboarding.vue").then(m => m.default || m),
    children: [
  {
    name: "onboarding-female-activity-level",
    path: "female/activity-level",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/activity-level.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-are-your-mother",
    path: "female/are-your-mother",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/are-your-mother.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-birthday",
    path: "female/birthday",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/birthday.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-body-shape-info",
    path: "female/body-shape-info",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/body-shape-info.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-can-you-go-to-gym",
    path: "female/can-you-go-to-gym",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/can-you-go-to-gym.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-chapter-body-data",
    path: "female/chapter-body-data",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/chapter-body-data.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-chapter-fitness-analysis",
    path: "female/chapter-fitness-analysis",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/chapter-fitness-analysis.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-chapter-goal",
    path: "female/chapter-goal",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/chapter-goal.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-checkout",
    path: "female/checkout",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/checkout.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-child-age",
    path: "female/child-age",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/child-age.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-current-body-shape",
    path: "female/current-body-shape",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/current-body-shape.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-current-weight",
    path: "female/current-weight",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/current-weight.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-easily-give-up",
    path: "female/easily-give-up",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/easily-give-up.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-faster-result",
    path: "female/faster-result",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/faster-result.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-feel-unsatisfied",
    path: "female/feel-unsatisfied",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/feel-unsatisfied.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-fitness-level",
    path: "female/fitness-level",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/fitness-level.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-flexibility",
    path: "female/flexibility",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/flexibility.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-focus-area",
    path: "female/focus-area",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/focus-area.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-generate-plan",
    path: "female/generate-plan",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/generate-plan.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-goal-info",
    path: "female/goal-info",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/goal-info.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-height",
    path: "female/height",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/height.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-how-often-workout",
    path: "female/how-often-workout",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/how-often-workout.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-how-will-celebrate",
    path: "female/how-will-celebrate",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/how-will-celebrate.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female",
    path: "female",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-main-goal",
    path: "female/main-goal",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/main-goal.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-motivation-photo-1",
    path: "female/motivation-photo-1",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/motivation-photo-1.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-motivation-photo-2",
    path: "female/motivation-photo-2",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/motivation-photo-2.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-motivation-photo-3",
    path: "female/motivation-photo-3",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/motivation-photo-3.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-motivation",
    path: "female/motivation",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/motivation.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-new-date",
    path: "female/new-date",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/new-date.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-payment",
    path: "female/payment",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/payment.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-personal-plan",
    path: "female/personal-plan",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/personal-plan.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-pick-up-suitable-workouts",
    path: "female/pick-up-suitable-workouts",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/pick-up-suitable-workouts.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-pre-focus-area",
    path: "female/pre-focus-area",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/pre-focus-area.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-prefer-workout",
    path: "female/prefer-workout",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/prefer-workout.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-preferred-workout-level",
    path: "female/preferred-workout-level",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/preferred-workout-level.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-preferred-workout-type",
    path: "female/preferred-workout-type",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/preferred-workout-type.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-social-proof",
    path: "female/social-proof",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/social-proof.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-special-event-info",
    path: "female/special-event-info",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/special-event-info.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-special-event",
    path: "female/special-event",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/special-event.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-stopping-your-goal-info",
    path: "female/stopping-your-goal-info",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/stopping-your-goal-info.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-stopping-your-goal",
    path: "female/stopping-your-goal",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/stopping-your-goal.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-subscribe-for-early-birds",
    path: "female/subscribe-for-early-birds",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/subscribe-for-early-birds.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-suffered-any-discomfort",
    path: "female/suffered-any-discomfort",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/suffered-any-discomfort.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-target-body-shape",
    path: "female/target-body-shape",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/target-body-shape.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-target-date",
    path: "female/target-date",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/target-date.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-target-weight",
    path: "female/target-weight",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/target-weight.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-female-we-can-make-it",
    path: "female/we-can-make-it",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/female/we-can-make-it.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-gender",
    path: "gender",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/gender.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-male-goal",
    path: "male/goal",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/male/goal.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-male",
    path: "male",
    component: () => import("/opt/buildhome/repo/src/pages/onboarding/male/index.vue").then(m => m.default || m)
  }
]
  }
]